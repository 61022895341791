/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listS3 = /* GraphQL */ `
  query ListS3($key: String, $bucket: String) {
    listS3(key: $key, bucket: $bucket)
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      cognitoId
      email
      name
      familyName
      company
      isAuthorized
      createdAt
      updatedAt
      roles
      coreId
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $id: ID
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        cognitoId
        email
        name
        familyName
        company
        isAuthorized
        createdAt
        updatedAt
        roles
        coreId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserAndCognitoGroups = /* GraphQL */ `
  query GetUserAndCognitoGroups {
    getUserAndCognitoGroups
  }
`;
export const uptUserAndCognitoGroups = /* GraphQL */ `
  query UptUserAndCognitoGroups(
    $id: ID!
    $name: String!
    $familyName: String!
    $isAuthorized: Boolean!
    $roles: [String]!
    $company: String!
  ) {
    uptUserAndCognitoGroups(
      id: $id
      name: $name
      familyName: $familyName
      isAuthorized: $isAuthorized
      roles: $roles
      company: $company
    )
  }
`;
